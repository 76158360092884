import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <section id="home" className="module module-image module-fit-height">
        <div className="container">
          <div className="module-header wow fadeInUp" style={{paddingTop: '25vh'}}>
            <h2 className="module-title">Not Found</h2>
            <p className="module-subtitle">You just hit a route that doesn&#39;t exist...</p>
            <Link to="/" className="btn-custom-2">Return Home</Link>
            <div className="module-line"></div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
